/* eslint-disable class-methods-use-this */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
import { createRef, Component, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import memoize from 'lodash.memoize';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import reduce from 'lodash.reduce';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import startsWith from 'lodash.startswith';
import classNames from 'classnames';
import './src/utils/prototypes';

import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { FixedSizeList } from 'react-window';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Flag from 'react-world-flags';
import CountryData from './src/CountryData';

const getDropdownCountryName = (country: any) => {
  return country.localName || country.name;
};

function renderRow(props: any) {
  const {
    index,
    style,
    data: { countries, handleFlagItemClick, onClick },
  } = props;

  const country = countries[index];
  if (!country) {
    return null;
  }

  const _onClick = (e: any) => {
    handleFlagItemClick(country.country, e);
    onClick(country.country, e);
  };

  return (
    <ListItem onClick={_onClick} button style={style} key={index}>
      <ListItemIcon>
        <Flag height={16} code={country.countryIso} />
      </ListItemIcon>
      <ListItemText primary={country.countryName} secondary={country.countryFormat} />
    </ListItem>
  );
}

function VirtualizedList({ countries, handleFlagItemClick, onClick }: any) {
  return (
    <Box sx={{ width: '100%', height: 400, maxWidth: 360, bgcolor: 'background.paper' }}>
      <FixedSizeList
        itemData={{ countries, handleFlagItemClick, onClick }}
        height={400}
        width={360}
        itemSize={46}
        itemCount={200}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  );
}

const ButtonFlags = ({ countries: _countries, selectedCountry, handleFlagItemClick }: any) => {
  const [anchorEl, setAnchorEl] = useState();
  const [countries, setCountries] = useState(_countries);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setAnchorEl(null);
  };

  const onChange = (event: any) => {
    const { value } = event.target;
    const newCountries = _countries.filter(
      (country: any) =>
        String(country.countryName).toLowerCase().indexOf(String(value).toLowerCase()) !== -1 ||
        String(country.countryFormat).toLowerCase().indexOf(String(value).toLowerCase()) !== -1
    );
    setCountries(newCountries);
  };

  const inputRef = useRef();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClick = () => {
    handleClose();
  };

  return (
    <div>
      <Button
        startIcon={
          <Flag
            height={16}
            code={!!selectedCountry && String(selectedCountry.iso2).toUpperCase()}
            fallBack={<span>Unknown</span>}
          />
        }
        size="small"
        aria-describedby={id}
        onClick={handleClick}
      >
        <KeyboardArrowDownIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <TextField
            autoFocus
            fullWidth
            inputRef={inputRef}
            onChange={onChange}
            label="Search country"
          />
        </Box>
        <List component="nav" aria-label="main countries">
          <VirtualizedList
            onClick={onClick}
            handleFlagItemClick={handleFlagItemClick}
            countries={countries}
          />
        </List>
      </Popover>
    </div>
  );
};

type OwnPhoneInputProps = {
  country?: string | number;
  value?: string;
  onlyCountries?: string[];
  preferredCountries?: string[];
  excludeCountries?: string[];
  placeholder?: string;
  searchPlaceholder?: string;
  searchNotFound?: string;
  disabled?: boolean;
  containerStyle?: any;
  inputStyle?: any;
  buttonStyle?: any;
  dropdownStyle?: any;
  searchStyle?: any;
  containerClass?: string;
  inputClass?: string;
  buttonClass?: string;
  dropdownClass?: string;
  searchClass?: string;
  autoFormat?: boolean;
  enableAreaCodes?: boolean | string[];
  enableTerritories?: boolean | string[];
  disableCountryCode?: boolean;
  disableDropdown?: boolean;
  enableLongNumbers?: boolean | number;
  countryCodeEditable?: boolean;
  enableSearch?: boolean;
  disableSearchIcon?: boolean;
  disableInitialCountryGuess?: boolean;
  disableCountryGuess?: boolean;
  regions?: string | string[];
  inputProps?: any;
  localization?: any;
  masks?: any;
  areaCodes?: any;
  preserveOrder?: string[];
  defaultMask?: string;
  alwaysDefaultMask?: boolean;
  prefix?: string;
  copyNumbersOnly?: boolean;
  renderStringAsFlag?: string;
  autocompleteSearch?: boolean;
  jumpCursorToEnd?: boolean;
  priority?: any;
  enableAreaCodeStretch?: boolean;
  enableClickOutside?: boolean;
  showDropdown?: boolean;
  onChange?: (...args: any[]) => any;
  onFocus?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  onKeyDown?: (...args: any[]) => any;
  onEnterKeyPress?: (...args: any[]) => any;
  isValid?: boolean | ((...args: any[]) => any);
  defaultErrorMessage?: string;
  specialLabel?: string;
};

type PhoneInputState = any;

const defaultProps = {
  country: '',
  value: '',
  onlyCountries: [],
  preferredCountries: [],
  excludeCountries: [],
  placeholder: '1 (702) 123-4567',
  searchPlaceholder: 'search',
  searchNotFound: 'No entries to show',
  flagsImagePath: './src/flags.png',
  disabled: false,
  containerStyle: {},
  inputStyle: {},
  buttonStyle: {},
  dropdownStyle: {},
  searchStyle: {},
  containerClass: '',
  inputClass: '',
  buttonClass: '',
  dropdownClass: '',
  searchClass: '',
  autoFormat: true,
  enableAreaCodes: false,
  enableTerritories: false,
  disableCountryCode: false,
  disableDropdown: false,
  enableLongNumbers: false,
  countryCodeEditable: true,
  enableSearch: false,
  disableSearchIcon: false,
  disableInitialCountryGuess: false,
  disableCountryGuess: false,
  regions: '',
  inputProps: {},
  localization: {},
  masks: null,
  priority: null,
  areaCodes: null,
  preserveOrder: [],
  defaultMask: '... ... ... ... ..',
  alwaysDefaultMask: false,
  prefix: '+',
  copyNumbersOnly: true,
  renderStringAsFlag: '',
  autocompleteSearch: false,
  jumpCursorToEnd: true,
  enableAreaCodeStretch: false,
  enableClickOutside: true,
  showDropdown: false,
  isValid: true,
  defaultErrorMessage: '',
  specialLabel: 'Phone',
  onEnterKeyPress: null,
  keys: {
    UP: 38,
    DOWN: 40,
    RIGHT: 39,
    LEFT: 37,
    ENTER: 13,
    ESC: 27,
    PLUS: 43,
    A: 65,
    Z: 90,
    SPACE: 32,
  },
};

type PhoneInputProps = OwnPhoneInputProps & typeof defaultProps;

class PhoneInput extends Component<PhoneInputProps, PhoneInputState> {
  static defaultProps = defaultProps;

  dropdownContainerRef: any;

  dropdownRef: any;

  numberInputRef: any;

  constructor(props: PhoneInputProps) {
    super(props);
    this.numberInputRef = createRef();
    const { onlyCountries, preferredCountries, hiddenAreaCodes } = new CountryData(
      (props as any).enableAreaCodes,
      (props as any).enableTerritories,
      (props as any).regions,
      (props as any).onlyCountries,
      (props as any).preferredCountries,
      (props as any).excludeCountries,
      (props as any).preserveOrder,
      (props as any).masks,
      (props as any).priority,
      (props as any).areaCodes,
      (props as any).localization,
      (props as any).prefix,
      (props as any).defaultMask,
      (props as any).alwaysDefaultMask
    );

    const inputNumber = (props as any).value ? (props as any).value.replace(/\D/g, '') : '';

    let countryGuess: any;
    if ((props as any).disableInitialCountryGuess) {
      countryGuess = 0;
    } else if (inputNumber.length > 1) {
      // Country detect by phone
      countryGuess =
        this.guessSelectedCountry(
          inputNumber.substring(0, 6),
          (props as any).country,
          onlyCountries,
          hiddenAreaCodes
        ) || 0;
    } else if ((props as any).country) {
      // Default country
      countryGuess = onlyCountries.find((o: any) => o.iso2 === (props as any).country) || 0;
    } else {
      // Empty params
      countryGuess = 0;
    }

    const dialCode =
      inputNumber.length < 2 && countryGuess && !startsWith(inputNumber, countryGuess.dialCode)
        ? countryGuess.dialCode
        : '';

    const formattedNumber =
      inputNumber === '' && countryGuess === 0
        ? ''
        : this.formatNumber(
            ((props as any).disableCountryCode ? '' : dialCode) + inputNumber,
            countryGuess.name ? countryGuess : undefined
          );

    const highlightCountryIndex = onlyCountries.findIndex((o: any) => o === countryGuess);

    this.state = {
      showDropdown: (props as any).showDropdown,
      formattedNumber,
      onlyCountries,
      preferredCountries,
      hiddenAreaCodes,
      selectedCountry: countryGuess,
      highlightCountryIndex,
      queryString: '',
      freezeSelection: false,
      debouncedQueryStingSearcher: debounce(this.searchCountry, 250),
      searchValue: '',
    };
  }

  componentDidMount() {
    if (document.addEventListener && (this.props as any).enableClickOutside) {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    if (document.removeEventListener && (this.props as any).enableClickOutside) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentDidUpdate(prevProps: PhoneInputProps) {
    if ((prevProps as any).country !== (this.props as any).country) {
      this.updateCountry((this.props as any).country);
    } else if ((prevProps as any).value !== (this.props as any).value) {
      this.updateFormattedNumber((this.props as any).value);
    }
  }

  getProbableCandidate = memoize((queryString: any) => {
    if (!queryString || queryString.length === 0) {
      return null;
    }
    // don't include the preferred countries in search
    const probableCountries = this.state.onlyCountries.filter((country: any) => {
      return startsWith(country.name.toLowerCase(), queryString.toLowerCase());
    }, this);
    return probableCountries[0];
  });

  guessSelectedCountry = memoize(
    (inputNumber: any, country: any, onlyCountries: any, hiddenAreaCodes: any) => {
      // if enableAreaCodes == false, try to search in hidden area codes to detect area code correctly
      // then search and insert main country which has this area code
      // https://github.com/bl00mber/react-phone-input-2/issues/201
      if ((this.props as any).enableAreaCodes === false) {
        let mainCode;
        hiddenAreaCodes.some((_country: any) => {
          if (startsWith(inputNumber, _country.dialCode)) {
            onlyCountries.some((o: any) => {
              if (_country.iso2 === o.iso2 && o.mainCode) {
                mainCode = o;
                return true;
              }
              return false;
            });
            return true;
          }
          return false;
        });
        if (mainCode) return mainCode;
      }

      const secondBestGuess = onlyCountries.find((o: any) => o.iso2 === country);
      if (inputNumber.trim() === '') return secondBestGuess;

      const bestGuess = onlyCountries.reduce(
        (selectedCountry: any, _country: any) => {
          if (startsWith(inputNumber, _country.dialCode)) {
            if (_country.dialCode.length > selectedCountry.dialCode.length) {
              return _country;
            }
            if (
              _country.dialCode.length === selectedCountry.dialCode.length &&
              _country.priority < selectedCountry.priority
            ) {
              return _country;
            }
          }
          return selectedCountry;
        },
        { dialCode: '', priority: 10001 },
        this
      );

      if (!bestGuess.name) return secondBestGuess;
      return bestGuess;
    }
  );

  // Hooks for updated props
  updateCountry = (country: any) => {
    const { onlyCountries } = this.state;
    let newSelectedCountry;
    if (country.indexOf(0) >= '0' && country.indexOf(0) <= '9') {
      // digit
      newSelectedCountry = onlyCountries.find((o: any) => o.dialCode === +country);
    } else {
      newSelectedCountry = onlyCountries.find((o: any) => o.iso2 === country);
    }
    if (newSelectedCountry && newSelectedCountry.dialCode) {
      this.setState({
        selectedCountry: newSelectedCountry,
        formattedNumber: (this.props as any).disableCountryCode
          ? ''
          : this.formatNumber(newSelectedCountry.dialCode, newSelectedCountry),
      });
    }
  };

  updateFormattedNumber(value: any) {
    if (value === null) return this.setState({ selectedCountry: 0, formattedNumber: '' });

    const { onlyCountries, selectedCountry, hiddenAreaCodes } = this.state;
    const { country, prefix } = this.props;

    if (value === '') return this.setState({ selectedCountry, formattedNumber: '' });

    const inputNumber = value.replace(/\D/g, '');
    let newSelectedCountry;
    let formattedNumber;

    // if new value start with selectedCountry.dialCode, format number, otherwise find newSelectedCountry
    if (selectedCountry && startsWith(value, prefix + selectedCountry.dialCode)) {
      formattedNumber = this.formatNumber(inputNumber, selectedCountry);
      this.setState({ formattedNumber });
    } else {
      if ((this.props as any).disableCountryGuess) {
        newSelectedCountry = selectedCountry;
      } else {
        newSelectedCountry =
          this.guessSelectedCountry(
            inputNumber.substring(0, 6),
            country,
            onlyCountries,
            hiddenAreaCodes
          ) || selectedCountry;
      }
      const dialCode =
        newSelectedCountry && startsWith(inputNumber, prefix + newSelectedCountry.dialCode)
          ? newSelectedCountry.dialCode
          : '';

      formattedNumber = this.formatNumber(
        ((this.props as any).disableCountryCode ? '' : dialCode) + inputNumber,
        newSelectedCountry || undefined
      );
      this.setState({ selectedCountry: newSelectedCountry, formattedNumber });
    }
  }

  // View methods
  scrollTo = (country: any, middle: any) => {
    if (!country) return;
    const container = this.dropdownRef;
    if (!container || !document.body) return;

    const containerHeight = container.offsetHeight;
    const containerOffset = container.getBoundingClientRect();
    const containerTop = containerOffset.top + document.body.scrollTop;
    const containerBottom = containerTop + containerHeight;

    const element = country;
    const elementOffset = element.getBoundingClientRect();

    const elementHeight = element.offsetHeight;
    const elementTop = elementOffset.top + document.body.scrollTop;
    const elementBottom = elementTop + elementHeight;

    let newScrollTop = elementTop - containerTop + container.scrollTop;
    const middleOffset = containerHeight / 2 - elementHeight / 2;

    if (
      (this.props as any).enableSearch ? elementTop < containerTop + 32 : elementTop < containerTop
    ) {
      // scroll up
      if (middle) {
        newScrollTop -= middleOffset;
      }
      container.scrollTop = newScrollTop;
    } else if (elementBottom > containerBottom) {
      // scroll down
      if (middle) {
        newScrollTop += middleOffset;
      }
      const heightDifference = containerHeight - elementHeight;
      container.scrollTop = newScrollTop - heightDifference;
    }
  };

  scrollToTop = () => {
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    container.scrollTop = 0;
  };

  formatNumber = (text: any, country: any) => {
    if (!country) return text;

    const { format } = country;
    const { disableCountryCode, enableAreaCodeStretch, enableLongNumbers, autoFormat } = this.props;

    let pattern;
    if (disableCountryCode) {
      pattern = format.split(' ');
      pattern.shift();
      pattern = pattern.join(' ');
    } else if (enableAreaCodeStretch && country.isAreaCode) {
      pattern = format.split(' ');
      pattern[1] = pattern[1].replace(/\.+/, ''.padEnd(country.areaCodeLength, '.'));
      pattern = pattern.join(' ');
    } else {
      pattern = format;
    }

    if (!text || text.length === 0) {
      return disableCountryCode ? '' : (this.props as any).prefix;
    }

    // for all strings with length less than 3, just return it (1, 2 etc.)
    // also return the same text if the selected country has no fixed format
    if ((text && text.length < 2) || !pattern || !autoFormat) {
      return disableCountryCode ? text : (this.props as any).prefix + text;
    }

    const formattedObject = reduce(
      pattern,
      (acc: any, character: any) => {
        if (acc.remainingText.length === 0) {
          return acc;
        }

        if (character !== '.') {
          return {
            formattedText: acc.formattedText + character,
            remainingText: acc.remainingText,
          };
        }

        const [head, ...tail] = acc.remainingText;

        return {
          formattedText: acc.formattedText + head,
          remainingText: tail,
        };
      },
      {
        formattedText: '',
        remainingText: text.split(''),
      }
    );

    let formattedNumber;
    if (enableLongNumbers) {
      formattedNumber = formattedObject.formattedText + formattedObject.remainingText.join('');
    } else {
      formattedNumber = formattedObject.formattedText;
    }

    // Always close brackets
    if (formattedNumber.includes('(') && !formattedNumber.includes(')')) formattedNumber += ')';
    return formattedNumber;
  };

  // Put the cursor to the end of the input (usually after a focus event)
  cursorToEnd = () => {
    const input = this.numberInputRef.current;
    input.focus();
    let len = input.value.length;
    if (input.value.charAt(len - 1) === ')') len -= 1;
    input.setSelectionRange(len, len);
  };

  getElement = (index: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return this[`flag_no_${index}`];
  };

  // return country data from state
  getCountryData = () => {
    if (!this.state.selectedCountry) return {};
    return {
      name: this.state.selectedCountry.name || '',
      dialCode: this.state.selectedCountry.dialCode || '',
      countryCode: this.state.selectedCountry.iso2 || '',
      format: this.state.selectedCountry.format || '',
    };
  };

  handleFlagDropdownClick = (e: any) => {
    e.preventDefault();
    if (!this.state.showDropdown && (this.props as any).disabled) return;
    const { preferredCountries, selectedCountry } = this.state;
    const allCountries = preferredCountries.concat(this.state.onlyCountries);

    const highlightCountryIndex = allCountries.findIndex(
      (o: any) => o.dialCode === selectedCountry.dialCode && o.iso2 === selectedCountry.iso2
    );

    this.setState(
      {
        showDropdown: !this.state.showDropdown,
        highlightCountryIndex,
      },
      () => {
        if (this.state.showDropdown) {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
          this.scrollTo(this.getElement(this.state.highlightCountryIndex));
        }
      }
    );
  };

  handleInput = (e: any) => {
    const { value } = e.target;
    const { prefix, onChange } = this.props;

    let formattedNumber = (this.props as any).disableCountryCode ? '' : prefix;
    let newSelectedCountry = this.state.selectedCountry;
    let { freezeSelection } = this.state;

    if (!(this.props as any).countryCodeEditable) {
      const mainCode = newSelectedCountry.hasAreaCodes
        ? this.state.onlyCountries.find(
            (o: any) => o.iso2 === newSelectedCountry.iso2 && o.mainCode
          ).dialCode
        : newSelectedCountry.dialCode;

      const updatedInput = prefix + mainCode;
      if (value.slice(0, updatedInput.length) !== updatedInput) return;
    }

    if (value === prefix) {
      // we should handle change when we delete the last digit
      // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
      if (onChange) onChange('', this.getCountryData(), e, '');
      return this.setState({ formattedNumber: '' });
    }

    // Does exceed default 15 digit phone number limit
    if (value.replace(/\D/g, '').length > 15) {
      if ((this.props as any).enableLongNumbers === false) return;
      if (typeof (this.props as any).enableLongNumbers === 'number') {
        if (value.replace(/\D/g, '').length > (this.props as any).enableLongNumbers) return;
      }
    }

    // if the input is the same as before, must be some special key like enter etc.
    if (value === this.state.formattedNumber) return;

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    const { country } = this.props;
    const { onlyCountries, selectedCountry, hiddenAreaCodes } = this.state;

    if (onChange) e.persist();

    if (value.length > 0) {
      // before entering the number in new format, lets check if the dial code now matches some other country
      const inputNumber = value.replace(/\D/g, '');

      // we don't need to send the whole number to guess the country... only the first 6 characters are enough
      // the guess country function can then use memoization much more effectively since the set of input it
      // gets has drastically reduced
      if (!this.state.freezeSelection || selectedCountry.dialCode.length > inputNumber.length) {
        if ((this.props as any).disableCountryGuess) {
          newSelectedCountry = selectedCountry;
        } else {
          newSelectedCountry =
            this.guessSelectedCountry(
              inputNumber.substring(0, 6),
              country,
              onlyCountries,
              hiddenAreaCodes
            ) || selectedCountry;
        }
        freezeSelection = false;
      }
      formattedNumber = this.formatNumber(inputNumber, newSelectedCountry);
      newSelectedCountry = newSelectedCountry.dialCode ? newSelectedCountry : selectedCountry;
    }

    let caretPosition = e.target.selectionStart;
    const oldFormattedText = this.state.formattedNumber;
    const diff = formattedNumber.length - oldFormattedText.length;

    this.setState(
      {
        formattedNumber,
        freezeSelection,
        selectedCountry: newSelectedCountry,
      },
      () => {
        if (diff > 0) {
          caretPosition -= diff;
        }

        const lastChar = formattedNumber.charAt(formattedNumber.length - 1);

        if (lastChar === ')') {
          this.numberInputRef.current.setSelectionRange(
            formattedNumber.length - 1,
            formattedNumber.length - 1
          );
        } else if (caretPosition > 0 && oldFormattedText.length >= formattedNumber.length) {
          this.numberInputRef.current.setSelectionRange(caretPosition, caretPosition);
        }

        if (onChange)
          // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
          onChange(
            formattedNumber,
            this.getCountryData(),
            e,
            formattedNumber.replace(/[^0-9]+/g, '')
          );
      }
    );
  };

  handleInputClick = (e: any) => {
    this.setState({ showDropdown: false });
    if ((this.props as any).onClick) (this.props as any).onClick(e, this.getCountryData());
  };

  handleDoubleClick = (e: any) => {
    const len = e.target.value.length;
    e.target.setSelectionRange(0, len);
  };

  handleFlagItemClick = (country: any, e: any) => {
    const currentSelectedCountry = this.state.selectedCountry;
    const newSelectedCountry = this.state.onlyCountries.find((o: any) => o === country);
    if (!newSelectedCountry) return;

    const unformattedNumber = this.state.formattedNumber
      .replace(' ', '')
      .replace('(', '')
      .replace(')', '')
      .replace('-', '');
    const newNumber =
      unformattedNumber.length > 1
        ? unformattedNumber.replace(currentSelectedCountry.dialCode, newSelectedCountry.dialCode)
        : newSelectedCountry.dialCode;
    const formattedNumber = this.formatNumber(newNumber.replace(/\D/g, ''), newSelectedCountry);

    this.setState(
      {
        showDropdown: false,
        selectedCountry: newSelectedCountry,
        freezeSelection: true,
        formattedNumber,
      },
      () => {
        this.cursorToEnd();
        if ((this.props as any).onChange)
          (this.props as any).onChange(
            formattedNumber.replace(/[^0-9]+/g, ''),
            this.getCountryData(),
            e,
            formattedNumber
          );
      }
    );
  };

  handleInputFocus = (e: any) => {
    // if the input is blank, insert dial code of the selected country
    if (this.numberInputRef.current) {
      if (
        this.numberInputRef.current.value === (this.props as any).prefix &&
        this.state.selectedCountry &&
        !(this.props as any).disableCountryCode
      ) {
        this.setState(
          {
            formattedNumber: (this.props as any).prefix + this.state.selectedCountry.dialCode,
          },
          () => {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'jumpCursorToEnd' does not exist on type ... Remove this comment to see the full error message
            this.props.jumpCursorToEnd && setTimeout(this.cursorToEnd, 0);
          }
        );
      }
    }

    this.setState({ placeholder: '' });

    (this.props as any).onFocus && (this.props as any).onFocus(e, this.getCountryData());
    (this.props as any).jumpCursorToEnd && setTimeout(this.cursorToEnd, 0);
  };

  handleInputBlur = (e: any) => {
    if (!e.target.value) this.setState({ placeholder: (this.props as any).placeholder });
    (this.props as any).onBlur && (this.props as any).onBlur(e, this.getCountryData());
  };

  handleInputCopy = (e: any) => {
    if (!(this.props as any).copyNumbersOnly) return;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const text = window
      .getSelection()
      .toString()
      .replace(/[^0-9]+/g, '');
    e.clipboardData.setData('text/plain', text);
    e.preventDefault();
  };

  getHighlightCountryIndex = (direction: any) => {
    // had to write own function because underscore does not have findIndex. lodash has it
    const highlightCountryIndex = this.state.highlightCountryIndex + direction;

    if (
      highlightCountryIndex < 0 ||
      highlightCountryIndex >=
        this.state.onlyCountries.length + this.state.preferredCountries.length
    ) {
      return highlightCountryIndex - direction;
    }

    if (
      (this.props as any).enableSearch &&
      highlightCountryIndex > this.getSearchFilteredCountries().length
    )
      return 0; // select first country
    return highlightCountryIndex;
  };

  searchCountry = () => {
    const probableCandidate =
      this.getProbableCandidate(this.state.queryString) || this.state.onlyCountries[0];
    const probableCandidateIndex =
      this.state.onlyCountries.findIndex((o: any) => o === probableCandidate) +
      this.state.preferredCountries.length;

    this.scrollTo(this.getElement(probableCandidateIndex), true);

    this.setState({ queryString: '', highlightCountryIndex: probableCandidateIndex });
  };

  handleKeydown = (e: any) => {
    const { keys } = this.props;
    const {
      target: { className },
    } = e;

    if (
      className.includes('selected-flag') &&
      e.which === (keys as any).ENTER &&
      !this.state.showDropdown
    )
      return this.handleFlagDropdownClick(e);
    if (
      className.includes('form-control') &&
      (e.which === (keys as any).ENTER || e.which === (keys as any).ESC)
    )
      return e.target.blur();

    if (!this.state.showDropdown || (this.props as any).disabled) return;
    if (className.includes('search-box')) {
      if (
        e.which !== (keys as any).UP &&
        e.which !== (keys as any).DOWN &&
        e.which !== (keys as any).ENTER
      ) {
        if (e.which === (keys as any).ESC && e.target.value === '') {
          // do nothing // if search field is empty, pass event (close dropdown)
        } else {
          return; // don't process other events coming from the search field
        }
      }
    }

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    const moveHighlight = (direction: any) => {
      this.setState(
        {
          highlightCountryIndex: this.getHighlightCountryIndex(direction),
        },
        () => {
          this.scrollTo(this.getElement(this.state.highlightCountryIndex), true);
        }
      );
    };

    switch (e.which) {
      case (keys as any).DOWN:
        moveHighlight(1);
        break;
      case (keys as any).UP:
        moveHighlight(-1);
        break;
      case (keys as any).ENTER:
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        if (this.props.enableSearch) {
          this.handleFlagItemClick(
            this.getSearchFilteredCountries()[this.state.highlightCountryIndex] ||
              this.getSearchFilteredCountries()[0],
            e
          );
        } else {
          this.handleFlagItemClick(
            [...this.state.preferredCountries, ...this.state.onlyCountries][
              this.state.highlightCountryIndex
            ],
            e
          );
        }
        break;
      case (keys as any).ESC:
      case (keys as any).TAB:
        this.setState(
          {
            showDropdown: false,
          },
          this.cursorToEnd
        );
        break;
      default:
        if (
          (e.which >= (keys as any).A && e.which <= (keys as any).Z) ||
          e.which === (keys as any).SPACE
        ) {
          this.setState(
            {
              queryString: this.state.queryString + String.fromCharCode(e.which),
            },
            this.state.debouncedQueryStingSearcher
          );
        }
    }
  };

  handleInputKeyDown = (e: any) => {
    const { keys, onEnterKeyPress, onKeyDown } = this.props;
    if (e.which === (keys as any).ENTER) {
      if (onEnterKeyPress) onEnterKeyPress(e);
    }
    if (onKeyDown) onKeyDown(e);
  };

  handleClickOutside = (e: any) => {
    if (this.dropdownRef && !this.dropdownContainerRef.contains(e.target)) {
      this.state.showDropdown && this.setState({ showDropdown: false });
    }
  };

  handleSearchChange = (e: any) => {
    const {
      currentTarget: { value: searchValue },
    } = e;
    const { preferredCountries, selectedCountry } = this.state;
    let highlightCountryIndex = 0;

    if (searchValue === '' && selectedCountry) {
      const { onlyCountries } = this.state;
      highlightCountryIndex = preferredCountries
        .concat(onlyCountries)
        .findIndex((o: any) => o === selectedCountry);
      // wait asynchronous search results re-render, then scroll
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      setTimeout(() => this.scrollTo(this.getElement(highlightCountryIndex)), 100);
    }
    this.setState({ searchValue, highlightCountryIndex });
  };

  getSearchFilteredCountries = () => {
    const { preferredCountries, onlyCountries, searchValue } = this.state;
    const { enableSearch } = this.props;
    const allCountries = preferredCountries.concat(onlyCountries);
    const sanitizedSearchValue = searchValue.trim().toLowerCase();
    if (enableSearch && sanitizedSearchValue) {
      // [...new Set()] to get rid of duplicates
      // firstly search by iso2 code
      if (/^\d+$/.test(sanitizedSearchValue)) {
        // contains digits only
        // values wrapped in ${} to prevent undefined
        return allCountries.filter(({ dialCode }: any) =>
          [`${dialCode}`].some((field) => field.toLowerCase().includes(sanitizedSearchValue))
        );
      }
      const iso2countries = allCountries.filter(({ iso2 }: any) =>
        [`${iso2}`].some((field) => field.toLowerCase().includes(sanitizedSearchValue))
      );
      // || '' - is a fix to prevent search of 'undefined' strings
      // Since all the other values shouldn't be undefined, this fix was accepte
      // but the structure do not looks very good
      const searchedCountries = allCountries.filter(({ name, localName /* , iso2 */ }: any) =>
        [`${name}`, `${localName || ''}`].some((field) =>
          field.toLowerCase().includes(sanitizedSearchValue)
        )
      );
      this.scrollToTop();
      return [...new Set([].concat(iso2countries, searchedCountries))];
    }
    return allCountries;
  };

  getCountryDropdownList = (array = false) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const { preferredCountries, highlightCountryIndex, showDropdown, searchValue } = this.state;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const { disableDropdown, prefix } = this.props;
    const {
      enableSearch,
      searchNotFound,
      disableSearchIcon,
      searchClass,
      searchStyle,
      searchPlaceholder,
      autocompleteSearch,
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    } = this.props;

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const searchedCountries = this.getSearchFilteredCountries();

    if (array) {
      return searchedCountries.map((country: any) => {
        return {
          countryName: getDropdownCountryName(country),
          countryFormat: country.format
            ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              this.formatNumber(country.dialCode, country)
            : prefix + country.dialCode,
          countryIso: String(country.iso2).toUpperCase(),
          country,
        };
      });
    }

    const countryDropdownList = searchedCountries.map((country: any, index: any) => {
      const highlight = highlightCountryIndex === index;
      const itemClasses = classNames({
        country: true,
        preferred: country.iso2 === 'us' || country.iso2 === 'gb',
        active: country.iso2 === 'us',
        highlight,
      });

      const inputFlagClasses = `flag ${country.iso2}`;

      return (
        <li
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ref={(el) => (this[`flag_no_${index}`] = el)}
          key={`flag_no_${index}`}
          data-flag-key={`flag_no_${index}`}
          className={itemClasses}
          data-dial-code="1"
          tabIndex={disableDropdown ? -1 : 0}
          data-country-code={country.iso2}
          onClick={(e) => this.handleFlagItemClick(country, e)}
          role="option"
          {...(highlight ? { 'aria-selected': true } : {})}
        >
          <div className={inputFlagClasses} />
          <span className="country-name">{getDropdownCountryName(country)}</span>
          <span className="dial-code">
            {country.format
              ? this.formatNumber(country.dialCode, country)
              : prefix + country.dialCode}
          </span>
        </li>
      );
    });

    const dashedLi = <li key="dashes" className="divider" />;
    // let's insert a dashed line in between preffered countries and the rest
    preferredCountries.length > 0 &&
      (!enableSearch || (enableSearch && !searchValue.trim())) &&
      countryDropdownList.splice(preferredCountries.length, 0, dashedLi);

    const dropDownClasses = classNames({
      [(this.props as any).dropdownClass]: true,
      'country-list': true,
      hide: !showDropdown,
    });

    return (
      <ul
        ref={(el) => {
          !enableSearch && el && el.focus();
          return (this.dropdownRef = el);
        }}
        className={dropDownClasses}
        style={(this.props as any).dropdownStyle}
        role="listbox"
        tabIndex="0"
      >
        {enableSearch && (
          <li
            className={classNames({
              search: true,
              [searchClass]: searchClass,
            })}
          >
            {!disableSearchIcon && (
              <span
                className={classNames({
                  'search-emoji': true,
                  [`${searchClass}-emoji`]: searchClass,
                })}
                role="img"
                aria-label="Magnifying glass"
              >
                &#128270;
              </span>
            )}
            <input
              className={classNames({
                'search-box': true,
                [`${searchClass}-box`]: searchClass,
              })}
              style={searchStyle}
              type="search"
              placeholder={searchPlaceholder}
              autoFocus
              autoComplete={autocompleteSearch ? 'on' : 'off'}
              value={searchValue}
              onChange={this.handleSearchChange}
            />
          </li>
        )}
        {countryDropdownList.length > 0 ? (
          countryDropdownList
        ) : (
          <li className="no-entries-message">
            <span>{searchNotFound}</span>
          </li>
        )}
      </ul>
    );
  };

  render() {
    const { onlyCountries, selectedCountry, formattedNumber, hiddenAreaCodes } = this.state;
    const {
      // disableDropdown,
      // renderStringAsFlag,
      isValid,
      error,
      defaultErrorMessage,
      specialLabel,
      helperText,
    } = this.props;

    let isValidValue;
    let errorMessage;
    if (typeof isValid === 'boolean') {
      isValidValue = isValid;
    } else {
      const isValidProcessed = isValid(
        formattedNumber.replace(/\D/g, ''),
        selectedCountry,
        onlyCountries,
        hiddenAreaCodes
      );
      if (typeof isValidProcessed === 'boolean') {
        isValidValue = isValidProcessed;
        if (isValidValue === false) errorMessage = defaultErrorMessage;
      } else {
        // typeof === 'string'
        isValidValue = false;
        errorMessage = isValidProcessed;
      }
    }

    if (error && isValidValue) {
      isValidValue = false;
      errorMessage = helperText;
    }

    const containerClasses = classNames({
      [(this.props as any).containerClass]: true,
      'react-tel-input': true,
    });
    // const arrowClasses = classNames({ arrow: true, up: showDropdown });
    // const inputClasses = classNames({
    //   [this.props.inputClass]: true,
    //   'form-control': true,
    //   'invalid-number': !isValidValue,
    //   open: showDropdown,
    // });
    // const selectedFlagClasses = classNames({
    //   'selected-flag': true,
    //   open: showDropdown,
    // });
    // const flagViewClasses = classNames({
    //   [this.props.buttonClass]: true,
    //   'flag-dropdown': true,
    //   'invalid-number': !isValidValue,
    //   open: showDropdown,
    // });
    // const inputFlagClasses = `flag ${selectedCountry && selectedCountry.iso2}`;
    const MuiComponent = (this.props as any).component;

    return (
      <div
        className={containerClasses}
        style={(this.props as any).style || (this.props as any).containerStyle}
        onKeyDown={this.handleKeydown}
      >
        <MuiComponent
          style={(this.props as any).inputStyle}
          onChange={this.handleInput}
          onClick={this.handleInputClick}
          onDoubleClick={this.handleDoubleClick}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onCopy={this.handleInputCopy}
          value={formattedNumber}
          inputRef={this.numberInputRef}
          onKeyDown={this.handleInputKeyDown}
          placeholder={(this.props as any).placeholder}
          disabled={(this.props as any).disabled}
          fullWidth
          error={!isValidValue}
          helperText={errorMessage}
          type="tel"
          label={specialLabel}
          {...(this.props as any).inputProps}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ButtonFlags
                  handleFlagItemClick={this.handleFlagItemClick}
                  selectedCountry={selectedCountry}
                  countries={this.getCountryDropdownList(true)}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}

export default PhoneInput;
