/* eslint-disable max-len */
/* eslint-disable no-bitwise */
/* eslint-disable no-extend-native */
/**
 * String.prototype.padStart() polyfill
 * https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padEnd
 */
// @ts-expect-error ts-migrate(1208) FIXME: 'prototypes.tsx' cannot be compiled under '--isola... Remove this comment to see the full error message
if (!String.prototype.padEnd) {
  String.prototype.padEnd = function padEnd(targetLength, padString) {
    targetLength >>= 0; // floor if number or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ');
    if (this.length > targetLength) {
      return String(this);
    }

    targetLength -= this.length;
    if (targetLength > padString.length) {
      padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
    }
    return String(this) + padString.slice(0, targetLength);
  };
}
